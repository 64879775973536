import React from "react";
import './Timeline.css'

function Timeline ({item}){

   // console.log(item);
    return(
        <div className="TimelineContainer">
            <div className="sideline">
                <div className="greenCircle"/>
                <div className="greenLine"/>
            </div>
            <div className="main">
                <h4 className="Year">{item.properties.Year}</h4>
                <div className="KeyInfoContainer">
                    <h3 className="KeyInfoName">Average life expectancy</h3>
                    <h3 className="KeyInfoValue">{item.properties['Life expectancy'] ? item.properties['Life expectancy'] : 'Unknown'}</h3>
                </div>
                <div className="KeyInfoContainer">
                    <h3 className="KeyInfoName">Expenditure on Health</h3>
                    <h3 className="KeyInfoValue">{item.properties['Total expenditure'] ? item.properties['Total expenditure'] + '%' : 'Unknown'}</h3>
                </div>
                <div className="line"/>
                <div className="SecondaryInfocontainer">
                    <h3 className="SecondaryInfoName">BMI (Country Average)</h3>
                    <h3 className="SecondaryInfoValue">{item.properties.BMI ? item.properties.BMI : 'Unknown'}</h3>
                </div>
                <div className="SecondaryInfocontainer">
                    <h3 className="SecondaryInfoName">Measles (Reported Cases)</h3>
                    <h3 className="SecondaryInfoValue">{item.properties.Measles ? item.properties.Measles : 'Unknown'}</h3>
                </div>
                <div className="SecondaryInfocontainer">
                    <h3 className="SecondaryInfoName">HIV/AIDS (Deaths per 1000 births)</h3>
                    <h3 className="SecondaryInfoValue">{item.properties['HIV/AIDS'] ? item.properties['HIV/AIDS'] : 'Unknown'}</h3>
                </div>

            </div>
        </div>
    );
}

export default Timeline;