import React from "react";
import Search from "./Search";
import { useMap } from 'react-map-gl/maplibre';
import './Controller.css'
import SwipeableViews from 'react-swipeable-views';
import CountryInfo from "../CountryInfo/CountryInfo";


function Controller ({clickEvent}){
        const [country, setCountry] = React.useState();
        const [index, setIndex] = React.useState(0);
        const [goBack, setGoBack] = React.useState(false);

      React.useEffect(()=>{
        if(country && index == 0){
            console.log('this is the set country', country)
            setIndex(1)
        }
      },[country])

      React.useEffect(()=>{
        if(goBack == true){
            setIndex(0);
            setGoBack(false);
        }
      },[goBack])

      React.useEffect(()=>{
        if(clickEvent){
            setCountry(clickEvent)
        }
      },[clickEvent])

    return(
        <SwipeableViews 
            className="control-panel"  
            disabled={true}
            enableMouseEvents={false}
            index={index}
            ignoreNativeScroll={true}
        >
            <div className="container" style={{ overflow:'hidden'}} >
                {/* <Search clickEvent={clickEvent} setCountry={setCountry}/>  */}
                <div>
                    <p className="explanationCopy">
                        This is a data visualisation showing global expenditure on health and the consequences
                        of low investment from 2000 to 2015. 
                    </p>
                    <div className="datatsetFromContainer">
                        <h4 className="datasetFromCopy" >Dataset is from</h4>
                        <img src={require('../../Resources/whologo.png')} style={{width: 70, height: 20}}/>
                    </div>
                    
                </div>
                <div className="keyContainer">
                    <div style={{margin: 15}}>
                        <h3 style={{paddingTop: 10}}>Key</h3>
                        {/* <div className="keyExampleContainer">
                            <div className="keyCircleDark"/>
                            <p className="keyDescription">Average national life expectancy</p>
                        </div> */}

                        <div className="keyExampleContainer">
                            <div className="keyCircleLight"/>
                            <p className="keyDescription">Percentage of GDP on health</p>
                        </div>

                        <div className="line"/>

                        <div className="exampleContainer">
                            <div className="exampleInnerCircleFirst"/>
                            <p>High percentage of GDP expenditure spent on health</p>
                        </div>

                        <div className="exampleContainer">
                            <div className="exampleInnerCircleSecond"/>
                            <p>Low percentage of GDP expenditure spent on health</p>
                        </div>

                    </div>
                </div>
                <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>made by</p>
                    <a href="https://www.axamattic.com/" target="blank">
                        <img src={require('../../Resources/axamattic.png')} style={{width: 70, height: 30, marginLeft: 10}}/>
                    </a>
                </div>
            </div>
            <div className="InfoContainer">
                <CountryInfo country={country} setGoBack={setGoBack}/>
            </div>
            
        </SwipeableViews>
    )
}

export default React.memo(Controller);