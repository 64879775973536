import React from 'react';
import Map from './components/Map/Map.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <link href='https://unpkg.com/maplibre-gl@3.6.2/dist/maplibre-gl.css' rel='stylesheet' />
      <div className="vis-container">
        <Map/>
      </div>
    </div>
  );
}

export default App;
