import React from "react";
import './CountryInfo.css'
import * as d3 from "d3";
import lifedata from '../../Data/LifeWithAverages.geojson';
import Timeline from "../Timeline/Timeline";

function CountryInfo ({country, setGoBack}){

    const [data, setData] = React.useState(null);
    const [searchedCountries, setSearchedCountries] = React.useState(null);
    const [averageLifeExpectancy, setAverageLifeExpectancy] = React.useState(null);
    const [averageExpenditure, setAverageExpenditure] = React.useState(null);
    


    //console.log(country)

    React.useEffect(()=>{
        if(data == null){
            d3.json(lifedata)
                .then((res)=>{
                    setData(res.features)
                    //console.log(res.features);
                })
        }  
    },[]);

    const getAllSearchedCountries = React.useCallback(()=>{
        console.log('search countries function', country)
        const res = data.filter((val)=>{ return val.properties.Country == country.properties.Country || val.properties.Country.indexOf(country.place_name) !== -1})
        console.log('search countries function output', res)
        return res;

    })

    React.useEffect(()=>{
        if(data != null && country){
           // console.log('this is the country', country);
            const c = getAllSearchedCountries();
            setSearchedCountries(c);
            //console.log('this is the result of searching for country', c)

            if(c === undefined || c.length == 0){
                setSearchedCountries(null);
                return;
            }
            else{
                    //get radius of marker (inner and outer circle)
                   // console.log('calculating scales', c)
                    const avgExpen = c[0].properties.avgExpenditure;
                    const avglifeExpect = c[0].properties.avgLifeExpectancy;
                    const scaleExpend = d3.scaleLinear()
                                    .domain([0,20])
                                    .range([10,60]);
                    const scaleLifeExpect = d3.scaleLinear()
                                    .domain([0,600])
                                    .range([60,80]);

                    setAverageExpenditure(scaleExpend(avgExpen));
                    setAverageLifeExpectancy(scaleLifeExpect(avglifeExpect));

            }
        }
    },[data, country]);

    const goBackFunc = () =>  {
        //go back
        setGoBack(true)
        // clear data to reset scroll
        setSearchedCountries(null)
        setAverageExpenditure(null)
        setAverageLifeExpectancy(null)
    }


    return(
        <div className="country-info-container">
            <div className="backButton" onClick={() => goBackFunc()}>
                <img src={require('../../Resources/backarrow.png')} />
                <h4>back</h4>
            </div>
            {searchedCountries != null ?

            <>
                <h1 className="countryName">{country ? country.properties.Country : "No info for this country"}</h1>

                <div className="overviewContainer">
                    <h3 className="overviewHeader">Overview</h3>
                    <div className="exampleContainer">
                        <div className="exampleInnerCircle" style={{width: averageExpenditure, height: averageExpenditure}}/>
                    </div>
                    <div className="keyAveragesContainer">
                        <div className="keyAveragesLeft">
                            <div className="keyAveragesCircleDark"/>
                            <h3 className="keyAverageName">Average life expectancy</h3>
                        </div>
                        <h3 className="keyAverageValuesDark">{country.properties['avgLifeExpectancy'] ? country.properties['avgLifeExpectancy'] : 'Unknown'}</h3>
                    </div>
                    <div className="keyAveragesContainer">
                        <div className="keyAveragesLeft">
                            <div className="keyAveragesCircleGreen"/>
                            <h3 className="keyAverageName">Average expenditure on health</h3>
                        </div>
                        <h3 className="keyAverageValuesGreen">{country.properties['avgExpenditure'] ? country.properties['avgExpenditure'] + '%' : 'Unknown'}</h3>
                    </div>
                </div>
                <div className="timelineContainer">
                    <h3 className="timelineHeader">Timeline from 2000 - 2015</h3>
                    {
                        searchedCountries != null ? 
                            searchedCountries.map((object, i)=>{
                                return <Timeline item={object}/>

                            })
                        :null
                    }
                </div>
            </>
            :

                <>
                    <div className="noInfoContainer">
                        <h3 className="noInfoHeader">Sorry, we have no information on this place</h3>
                    </div>
                </>
            
            }
           
        </div>
    )
}

export default React.memo(CountryInfo);