import React from "react";
import Map, { Marker, Source, Layer, useMap, Popup } from 'react-map-gl/maplibre';
import data from '../../Data/LifeWithAverages.geojson'
import Controller from "../Controller/Controller";
import './Map.css'
import { map } from "d3";

function RenderMap(){

    const mapRef = React.useRef().current;
    const [hoverFeature, setHoverFeature] = React.useState();
    const [hoverEvent, setHoverEvent] = React.useState();
    const [clickEvent, setClickEvent] = React.useState();
    const filter = React.useMemo(() => ['in', 'Country', hoverEvent], [hoverEvent]);

    const onHover = React.useCallback(event => {
        const {
            features,
            point: {x, y}
          } = event;

        const hoveredFeature = features && features[0];
        
        if(hoveredFeature){
            setHoverFeature(features);
            setHoverEvent(event);
        }else{
            setHoverFeature(false);
            setHoverEvent(false);
        }

    }, []);

    const onClick = React.useCallback(event => {
        const {
            features,
            point: {x, y}
          } = event;

        const clickFeature = features && features[0];
        
        if(clickFeature){
            console.log(clickFeature)
            console.log(event)
            setClickEvent(clickFeature);
        }else{
            setClickEvent(false);
        }

    }, []);

    return (
        <>
            <Map
                initialViewState={{
                longitude: 9.21743,
                latitude: 50.31198,
                zoom: 3.5,
                minZoom: 3,
                maxZoom: 5
                }}
                maxZoom={6}
                style={{width: '100vw', height: '100vh'}}
                interactiveLayerIds={['expenditure']}
                ref={mapRef}
                onMouseMove={onHover}
                onClick={onClick}
                mapStyle="https://api.maptiler.com/maps/4bfe587a-6b8c-4cb0-a587-aefc127465e9/style.json?key=86UiaXBulCvqAE4SdEje"
            >
            
                <Source
                    id="life"
                    type="geojson"
                    data={data}
                >
                        {/* <Layer 
                            id="death" 
                            type="circle"
                            source="life"
                            paint={{
                                'circle-color': '#080808',
                                'circle-radius': ['interpolate', ['linear'], ['get', 'avgLifeExpectancy'],  0, 1, 15, 20]
                            }}
                        /> */}
                        <Layer
                            id="expenditure" 
                            type="circle"
                            source="life"
                            paint={{
                                'circle-color': '#00CC66',
                                'circle-radius': ['interpolate', ['linear'], ['get', 'avgExpenditure'], 0, 1, 15, 20]
                            }}
                        />
                </Source>
                {hoverFeature && (
                    <Popup
                        longitude={hoverEvent.lngLat.lng}
                        latitude={hoverEvent.lngLat.lat}
                        style={{padding:50, borderRadius:20}}
                        closeButton={false}
                    >
                        <h3 className="keyHoverFeatureCountry">{hoverFeature[0].properties.Country}</h3>
                        <div>
                            <div className="keyHoverFeature">
                                <div className="keyHoverFeatureLeft">
                                    <div className="keyHoverCircleDark"/>
                                    Average life expectancy
                                </div>
                                <div className="keyHoverFeatureRight">
                                    {hoverFeature[0].properties.avgLifeExpectancy}
                                </div> 
                            </div>
                            
                            <div className="keyHoverFeature">
                                <div className="keyHoverFeatureLeft">
                                    <div className="keyHoverCircleLight"/>
                                    Expenditure on health
                                </div>
                                <div className="keyHoverFeatureRight">
                                    {hoverFeature[0].properties.avgExpenditure + '%'}
                                </div> 
                            </div>
                        </div>
                    </Popup>
                )}
                <Controller clickEvent={clickEvent}/>
            </Map>

           
        </>
    )
}

export default React.memo(RenderMap);